import React, { useState } from 'react';
import { Typography, Container, Grid, Paper, Button, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';

// Ensure Buffer is available
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CheckoutPage = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handlePurchase = async () => {
    if (!publicKey) {
      setSnackbarMessage("Please connect your wallet first!");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setIsLoading(true);

    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey('72cot8t88ZHHnu7axvTkgrS6RBnvRmf8mymhmj4KJG2R'),
          lamports: 5 * LAMPORTS_PER_SOL
        })
      );

      const signature = await sendTransaction(transaction, connection);
      const confirmedTransaction = await connection.confirmTransaction(signature, 'confirmed');

      if (confirmedTransaction.value.err) {
        throw new Error('Transaction failed');
      }

      const transactionDetails = await connection.getConfirmedTransaction(signature);

      const transferInstruction = transactionDetails.transaction.message.instructions.find(
        (instruction) => instruction.programId.equals(SystemProgram.programId)
      );

      const fromPubkey = new PublicKey(transferInstruction.keys[0].pubkey);
      const toPubkey = new PublicKey(transferInstruction.keys[1].pubkey);
      const amount = transferInstruction.data.readUIntLE(1, 8);

      if (!fromPubkey.equals(publicKey) || !toPubkey.equals(new PublicKey('72cot8t88ZHHnu7axvTkgrS6RBnvRmf8mymhmj4KJG2R')) || amount !== 5 * LAMPORTS_PER_SOL) {
        throw new Error('Transaction details do not match');
      }

      setSnackbarMessage("Purchase successful! Please contact @ccc666333 on Telegram to receive your download code.");
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error during purchase:", error);
      setSnackbarMessage(`There was an error processing your purchase: ${error.message}. Please contact @ccc666333 on Telegram for help.`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>Checkout</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h4" gutterBottom>CRISP PUMP FUN TOOL</Typography>
            <Typography variant="body1" paragraph>
              Get ready to revolutionize your trading with our powerful tool!
            </Typography>
            <Typography variant="h6" gutterBottom>What you get:</Typography>
            <ul>
              <li>Lifetime updates</li>
              <li>24/7 support</li>
              <li>Satisfaction guarantee</li>
              <li>Crisp execution and delivery</li>
            </ul>
            <Typography variant="body1" paragraph>
              Refund Policy: If you don't make money in the first 7 days, you're eligible for a 75% refund.
            </Typography>
            <Typography variant="body1">
              Need help? Contact us on Telegram: <a href="https://t.me/ccc666333">@ccc666333</a>
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h4" gutterBottom>Complete Your Purchase   </Typography>
            <Typography variant="h5" gutterBottom>Price: 5 SOL</Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handlePurchase}
              disabled={isLoading || !publicKey}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Confirm Purchase'}
            </Button>
            {!publicKey && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                Please connect your wallet to make a purchase.
              </Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CheckoutPage;
