import React, { useState, useRef } from 'react';
import { Typography, Button, Grid, Card, CardContent, Container, Box, Link, Dialog, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Slider from 'react-slick';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SpeedIcon from '@mui/icons-material/Speed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeroSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(10, 0),
  textAlign: 'center',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(2),
  transition: 'transform 0.15s ease-in-out',
  '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
}));

const SliderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '640px',
  margin: '0 auto',
  marginBottom: theme.spacing(4),
}));

const VideoOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
  cursor: 'pointer',
}));

const features = [
  {
    title: "Transact on multiple wallets simulatenously",
    subtext: "Buy and Sell on up to 100 wallets all at once!",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    title: "Snipe Instantly",
    subtext: "Fastest Snipe's available using a websocket connection. Land block 1.",
    icon: <SpeedIcon />,
  },
  {
    title: "Launch a coin",
    subtext: "Create a coin and instantly buy in on as many wallets as you like. Sell all at once when you reach your intended profit!",
    icon: <RocketLaunchIcon />,
  },
  {
    title: "Anti Rug and Dev Scanning",
    subtext: "We scan the developers token holdings as well as make use of https://rugcheck.xyz/.",
    icon: <VisibilityIcon />,
  },
  {
    title: "Token Filtering",
    subtext: "Set your preferences for tokens to snipe.",
    icon: <FilterListIcon />,
  },
  {
    title: "And more",
    subtext: "Check out a list of all our features",
    icon: <SettingsIcon />,
    link: "/features",
  },
];

const photos = [
  '/photos/menu.png',
  '/photos/latestcoins.png',
  '/photos/antirug.png',
  '/photos/settings.png'
];

const HomePage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    afterChange: (index) => setCurrentSlide(index),
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (videoRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <Box>
      <HeroSection>
        <Container>
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to CRISP
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Revolutionize your pump.fun trading with our powerful tool. 
            You're guaranteed to profit, or your money back!
             Explore our features and get started today.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              component={RouterLink}
              to="/checkout"
            >
              Buy Now
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              component={RouterLink}
              to="/features"
            >
              Explore Features
            </Button>
          </Box>
        </Container>
      </HeroSection>

      <Container sx={{ py: 8 }}>
        <SliderContainer>
          <Slider {...settings}>
            {photos.map((photo, index) => (
              <Box key={index} sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <img
                  src={photo}
                  alt={`slide-${index}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain', // Change to 'contain' to fit the entire image
                    cursor: 'pointer',
                  }}
                  onClick={handleOpen}
                />
              </Box>
            ))}
            <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0, cursor: 'pointer' }}
                onClick={handleOpen}
                onError={(e) => console.error("Video error:", e)}
              >
                <source src="/videos/promo4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!isPlaying && (
                <VideoOverlay onClick={handleOpen}>
                  <PlayArrowIcon style={{ fontSize: 64, color: 'white' }} />
                </VideoOverlay>
              )}
            </Box>
          </Slider>
        </SliderContainer>

        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
            {currentSlide < photos.length ? (
              <img
                src={photos[currentSlide]}
                alt={`expanded-${currentSlide}`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // Change to 'contain' to fit the entire image
                }}
              />
            ) : (
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
                controls
                autoPlay
              >
                <source src="/videos/promo4.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </Box>
        </Dialog>

        <Typography variant="h3" gutterBottom align="center">Key Features</Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <StyledCard>
                <StyledAvatar>
                  {feature.icon}
                </StyledAvatar>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.subtext}
                  </Typography>
                  {feature.link && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      component={RouterLink}
                      to={feature.link}
                      sx={{ mt: 1 }}
                    >
                      Learn More
                    </Button>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>Why Choose CRISP PUMP FUN TOOL?</Typography>
          <Typography variant="body1" paragraph>
            ✓ Lifetime updates<br/>
            ✓ 24/7 support<br/>
            ✓ Satisfaction guarantee<br/>
            ✓ Crisp execution and delivery
          </Typography>
          <Typography variant="body1" paragraph>
            Get started today and experience the CRISP difference!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to="/checkout"
          >
            Buy Now
          </Button>
        </Box>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body1">
            Need help? Contact us on Telegram: <Link href="https://t.me/ccc666333" target="_blank" rel="noopener noreferrer">@ccc666333</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default HomePage;
