import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import FeaturesPage from './components/FeaturesPage';
import GalleryPage from './components/GalleryPage';
import UpcomingPage from './components/UpcomingPage';
import CheckoutPage from './components/CheckoutPage';
import Navigation from './components/Navigation';

function App() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/upcoming" element={<UpcomingPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
      </Routes>
    </>
  );
}

export default App;