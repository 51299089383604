import React from 'react';
import { Typography, Container, Box, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { motion } from 'framer-motion';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const upcomingFeatures = [
  "Added Raydium support to continue tracking for coins",
  "Copy trading functionality",
  "Advanced insider tracking algorithms",
  "Comprehensive wallet analysis tools",
  "In-depth coin analysis features",
  "Expanded trading capabilities for any coin",
  "Seamless Raydium pools/tokens launch integration",
  "Enhanced token liquidity management"
];

const UpcomingPage = () => {
  return (
    <Box sx={{ bgcolor: 'background.default', pt: 8, pb: 6 }}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Coming Soon
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph sx={{ mb: 6 }}>
          We're constantly innovating to bring you the most advanced Solana trading tools. Here's a sneak peek at what's coming next:
        </Typography>
        <StyledPaper
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <List>
            {upcomingFeatures.map((feature, index) => (
              <StyledListItem key={index}
                component={motion.li}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <ListItemIcon>
                  <StarIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </StyledListItem>
            ))}
          </List>
        </StyledPaper>
        <Typography variant="body1" align="center" sx={{ mt: 4 }}>
          Stay tuned for these exciting updates! We're working hard to bring you these features and more.
        </Typography>
      </Container>
    </Box>
  );
};

export default UpcomingPage;