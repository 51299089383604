import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { styled } from '@mui/material/styles';

const Logo = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial, sans-serif',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: theme.palette.secondary.main, // Use secondary color for better contrast
  textDecoration: 'none',
  flexGrow: 1,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: theme.spacing(1),
  fontWeight: 600,
}));

const Navigation = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ textAlign: 'center', padding: 2, backgroundColor: '#121212', height: '100%', color: 'white' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        CRISP PUMP FUN TOOL
      </Typography>
      <Divider sx={{ backgroundColor: 'gray' }} />
      <List>
        {['Home', 'Features', 'Gallery', 'Upcoming', 'Buy Now'].map((text, index) => (
          <ListItem button key={text} component={Link} to={index === 0 ? '/' : `/${text.toLowerCase().replace(' ', '-')}`} onClick={handleDrawerToggle}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider sx={{ backgroundColor: 'gray' }} />
      <WalletMultiButton style={{ margin: '16px auto' }} />
    </Box>
  );

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#121212', color: 'white' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Logo component={Link} to="/">
            CRISP PUMP FUN TOOL
          </Logo>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <StyledButton component={Link} to="/">Home</StyledButton>
            <StyledButton component={Link} to="/features">Features</StyledButton>
            <StyledButton component={Link} to="/gallery">Gallery</StyledButton>
            <StyledButton component={Link} to="/upcoming">Upcoming</StyledButton>
            <StyledButton component={Link} to="/checkout">Buy Now</StyledButton>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <WalletMultiButton />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240, backgroundColor: '#121212', color: 'white' },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Navigation;
