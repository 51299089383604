import React from 'react';
import { Typography, Grid, Card, CardContent, Container, Box, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FilterListIcon from '@mui/icons-material/FilterList';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SpeedIcon from '@mui/icons-material/Speed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const StyledCard = styled(motion(Card))(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
}));

const features = [
  {
    title: "Latest Coin & King of the Hill Purchase",
    description: "Automatically buy the latest listed coins and the most popular coin on Solana.",
    icon: <AutoAwesomeIcon />,
  },
  {
    title: "Take Profit/Stop Loss",
    description: "Set your intended profit % as well as your Maximum Loss % and automatically sell when your targets are hit.",
    icon: <TrendingUpIcon />,
  },
  {
    title: "Auto Snipe",
    description: "Customize filters and automatically snipe tokens that meet your criteria.",
    icon: <FilterListIcon />,
  },
  {
    title: "Token Launch",
    description: "Launch new tokens with ease, complete with metadata. Instantly buy the token on the launching wallet and then buy it on multiple other wallets.",
    icon: <RocketLaunchIcon />,
  },
  {
    title: "Manage Settings",
    description: "Adjust buy amounts, profit-taking, stop-loss, and filter criteria to suit your trading strategy.",
    icon: <SettingsIcon />,
  },
  {
    title: "View Positions",
    description: "Monitor your current holdings and their performance in real-time.",
    icon: <AssessmentIcon />,
  },
  {
    title: "Volume Booster",
    description: "Execute secure and efficient volume-boosting transactions to add volume to your tokens.",
    icon: <SpeedIcon />,
  },
  {
    title: "Bump Bot",
    description: "Automatically keep your token on the main page for increased visibility.",
    icon: <VisibilityIcon />,
  },
  {
    title: "Multi-Wallet Trading",
    description: "Seamlessly buy and sell any token on multiple wallets at once.",
    icon: <AccountBalanceWalletIcon />,
  },
];

const FeatureCard = ({ title, description, icon, index }) => (
  <Grid item xs={12} sm={6} md={4}>
    <StyledCard
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1, duration: 0.5 }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <StyledAvatar>
          {icon}
        </StyledAvatar>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </StyledCard>
  </Grid>
);

const FeaturesPage = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Powerful Features
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph sx={{ mb: 6 }}>
          Discover the advanced capabilities that make our Solana Sniper Tool the ultimate choice for serious traders.
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesPage;
