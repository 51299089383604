import React, { useState } from 'react';
import { Typography, Container, Box, Grid, Card, CardMedia, Modal, IconButton, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

const StyledCard = styled(motion(Card))(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: '56.25%', // 16:9 aspect ratio
  backgroundSize: 'contain', // Ensure the entire image is visible without cropping
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const galleryItems = [
  { src: '/photos/menu.png', alt: 'Menu Interface', description: 'Navigate through the various features of the Solana Sniper Tool.' },
  { src: '/photos/latestcoins.png', alt: 'Latest Coins', description: 'View and interact with the latest coins available on Solana.' },
  { src: '/photos/autosnipe.png', alt: 'Auto Snipe Feature', description: 'Automatically snipe tokens that meet your criteria using the Auto Snipe feature.' },
  { src: '/photos/tokenlaunch.png', alt: 'Token Launch Interface', description: 'Easily launch new tokens with complete metadata and manage their purchase on multiple wallets.' },
  { src: '/photos/antirug.png', alt: 'Anti Rug and Dev Scanning', description: 'Scan developers\' token holdings and use anti-rug measures to ensure safe trading.' },
  { src: '/photos/positionmanagement.png', alt: 'Position Management', description: 'Monitor and manage your trading positions in real-time for optimal performance.' },
  { src: '/photos/settings.png', alt: 'Settings', description: 'Adjust settings to customize your trading experience with the Solana Sniper Tool.' },
];

const GalleryPage = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
      <Container maxWidth="lg">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          Tool Gallery
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph sx={{ mb: 6 }}>
          Explore the powerful features and intuitive interface of our Solana Sniper Tool.
        </Typography>
        <Grid container spacing={4}>
          {galleryItems.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <StyledCard
                onClick={() => handleOpen(item)}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <StyledCardMedia
                  image={item.src}
                  title={item.alt}
                />
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {item.alt}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="image-modal"
          aria-describedby="image-modal-description"
        >
          <ModalContent>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            {selectedImage && (
              <>
                <img src={selectedImage.src} alt={selectedImage.alt} style={{ width: '100%', height: 'auto' }} />
                <Typography variant="h6" sx={{ mt: 2 }}>{selectedImage.alt}</Typography>
                <Typography variant="body2" color="text.secondary">{selectedImage.description}</Typography>
              </>
            )}
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default GalleryPage;
